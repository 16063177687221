import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GetAllPricePlans } from "../../../network/apis/pricePlan";
import { AddSubscriptionToUser } from "../../../network/apis/subscriptions";

function AddSubscriptionModel({ modal, setModal, user, refetchData }) {
  const { handleSubmit } = useForm();

  const [requestingPaymentLink, setRequestingPaymentLink] = useState(false);

  // submit function to add a new item
  const onFormSubmit = async () => {
    await AddInvoice();
  };

  const onFormReset = () => {
    setSelectedPlan(null);
    setSelectedPlanType(null);
    setSelectedCurrency(null);
    setModal({ addSubscription: false });
    setIsWithBot(false);
  };

  const AddInvoice = async () => {
    try {
      setRequestingPaymentLink(true);

      const res = await AddSubscriptionToUser({
        userId: user.uid,
        planId: selectedPlan.id,
        currency: selectedCurrency.value,
        isBotIncluded: isWithBot ? true : false,
        duration: selectedPlanType.value ?? 1,
      });

      if (res.result === "success") {
        toast.success("Subscription added successfully");
        if (refetchData && typeof refetchData === "function") {
          refetchData();
        }
        onFormReset();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error?.message ?? "Something went wrong. Please try again later.");
    } finally {
      setRequestingPaymentLink(false);
    }
  };

  const [fetchingPlans, setFetchingPlans] = useState(true);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanType, setSelectedPlanType] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isWithBot, setIsWithBot] = useState(false);

  const fetchPlans = async () => {
    try {
      setFetchingPlans(true);
      const res = await GetAllPricePlans();
      if (res.status === "success") {
        setPlans(res.data);
      } else {
        toast.error(res.message);
        setPlans([]);
      }
    } catch (error) {
      setPlans([]);
      toast.error(error?.message ?? "Something went wrong. Please try again later.");
    } finally {
      setFetchingPlans(false);
    }
  };

  useEffect(() => {
    if (modal.addSubscription) {
      fetchPlans();
    }
  }, [modal.addSubscription]);

  return (
    <Modal
      isOpen={modal.addSubscription}
      toggle={() => setModal({ addSubscription: false })}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            onFormReset();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        {fetchingPlans ? (
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        ) : (
          <div className="p-2">
            <h5 className="title">
              Add New Subscription
              <span className="fs-6 fw-normal text-muted d-block mt-1">
                Fill in the details to add a new subscription
              </span>
            </h5>
            <div className="mt-4">
              <Form className="row gy-4 mt-4" onSubmit={handleSubmit(onFormSubmit)} onReset={onFormReset}>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Plan</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={plans.map((plan) => {
                          return {
                            label: plan.title,
                            value: plan.id,
                          };
                        })}
                        value={{
                          label: selectedPlan?.title ?? "Select a plan",
                          value: selectedPlan?.id ?? "Select a plan",
                        }}
                        onChange={(e) => {
                          const plan = plans.find((plan) => plan.id === e.value);
                          setSelectedPlan(plan);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Subscription Duration</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={[
                          {
                            label: "1 Month",
                            value: 1,
                          },
                          {
                            label: "2 Months",
                            value: 2,
                          },
                          {
                            label: "3 Months",
                            value: 3,
                          },
                          {
                            label: "6 Months",
                            value: 6,
                          },
                          {
                            label: "1 Year",
                            value: 12,
                          },
                        ]}
                        value={
                          selectedPlanType ?? {
                            label: "Select a subscription duration",
                            value: "Select a subscription duration",
                          }
                        }
                        onChange={(e) => {
                          setSelectedPlanType(e);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Currency</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={[
                          {
                            label: "USD",
                            value: "USD",
                          },
                          {
                            label: "Toman (IRR)",
                            value: "IRR",
                          },
                        ]}
                        value={
                          selectedCurrency ?? {
                            label: "Select currency",
                            value: "Select currency",
                          }
                        }
                        onChange={(e) => {
                          setSelectedCurrency(e);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                {selectedPlan && selectedPlan.isBotOptionAvailable && (
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Include Bot Access</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={[
                            {
                              label: "Yes",
                              value: true,
                            },
                            {
                              label: "No",
                              value: false,
                            },
                          ]}
                          value={{
                            label: isWithBot ? "Yes" : "No",
                            value: isWithBot,
                          }}
                          onChange={(e) => {
                            setIsWithBot(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                )}

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                        disabled={requestingPaymentLink || !selectedPlan || !selectedPlanType || !selectedCurrency}
                      >
                        {requestingPaymentLink ? "Adding..." : "Add Subscription"}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormReset();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}

export default AddSubscriptionModel;
