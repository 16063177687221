import { get, put, del, post } from "../../network/Config/apiService";

const AddPricePlan = async (data) => {
  try {
    await post("/admin/pricing/add", data);

    return {
      status: "success",
      message: "Price plan added successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error?.response?.data?.message || "Could not add price plan",
    };
  }
};

const UpdatePricePlan = async (data) => {
  try {
    await put(`/admin/pricing/${data.id}`, data);

    return {
      status: "success",
      message: "Price plan updated successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error?.response?.data?.message || "Could not update price plan",
    };
  }
};

const DeletePricePlan = async (id) => {
  if (!id) {
    throw new Error("Price plan ID is required");
  }

  try {
    await del(`/admin/pricing/${id}`);

    return {
      status: "success",
      message: "Price plan deleted successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error?.response?.data?.message || "Could not delete price plan",
    };
  }
};

const GetAllPricePlans = async () => {
  try {
    const res = await get("/admin/pricing");

    const data = res.data.pricePlans;

    return {
      status: "success",
      message: "Price plans fetched successfully",
      data: data,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not fetch price plans",
      data: null,
    };
  }
};

export { GetAllPricePlans, AddPricePlan, UpdatePricePlan, DeletePricePlan };
