import { get, put, post } from "../../network/Config/apiService";

const defaultProfile = {
  name: "",
  displayName: "",
  dob: new Date().toISOString(),
  email: "",
  phone: "",
  address: "",
  address2: "",
  state: "",
  country: "",
};

// Get Profile Data
const GetUserData = async (userUuid) => {
  try {
    const res = await get(`/admin/user/${userUuid}`);

    const userData = res.data.data;

    return {
      result: "success",
      message: "User data fetched Successfully",
      userData: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not fetch user data",
      userData: null,
    };
  }
};

// Update user profile
const UpdateProfile = async (userUuid, data) => {
  try {
    await put(`/admin/user/${userUuid}`, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not update user profile",
      data: null,
    };
  }
};

// Update User Profile Image
const UpdateProfileImage = async (userUuid, image) => {
  return {
    result: "error",
    message: "Temporary disabled",
    data: null,
  };
};

const SendPasswordResetEmail = async (email) => {
  try {
    await post("/auth/send-reset-password-link", { email: email });

    return {
      result: "success",
      message: "Reset link sent to email address",
      data: null,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.message,
      data: null,
    };
  }
};

export { defaultProfile, GetUserData, UpdateProfile, UpdateProfileImage, SendPasswordResetEmail };
