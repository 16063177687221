import { get, put, del, post } from "../../network/Config/apiService";

// Subscription
const GetAllSubscriptions = async () => {
  const res = await get("/admin/subscriptions");

  const subscriptions = res.data.subscriptions;

  return {
    result: "success",
    message: "Subscriptions fetched successfully",
    subscriptions: subscriptions,
  };
};

const GetSubscriptionsOfUser = async (userId) => {
  if (!userId) {
    throw new Error("User ID is required");
  }

  const res = await get(`/admin/subscriptions/${userId}`);

  const subscriptions = res.data.subscriptions;

  return {
    result: "success",
    message: "Subscriptions fetched successfully",
    subscriptions: subscriptions,
  };
};

const DeleteSubscription = async (subscriptionId) => {
  if (!subscriptionId) {
    throw new Error("Subscription ID is required");
  }

  await del(`/admin/subscription/delete/${subscriptionId}`);

  return {
    result: "success",
    message: "Subscription deleted successfully",
  };
};

const UpdateSubscriptionStatus = async (subscriptionId, status) => {
  if (!subscriptionId) {
    throw new Error("Subscription ID is required");
  }

  if (!status) {
    throw new Error("Status is required");
  }

  await put(`/admin/subscription/update-status/${subscriptionId}`, { status });

  return {
    result: "success",
    message: "Subscription status updated successfully",
  };
};

const AddSubscriptionToUser = async (data) => {
  await post("/admin/subscription/add", data);

  return {
    result: "success",
    message: "Subscription added successfully",
  };
};

const UpdateSubscription = async (data) => {
  await put("/admin/subscription/update", data);

  return {
    result: "success",
    message: "Subscription updated successfully",
  };
};

export {
  GetAllSubscriptions,
  GetSubscriptionsOfUser,
  DeleteSubscription,
  UpdateSubscriptionStatus,
  AddSubscriptionToUser,
  UpdateSubscription,
};
