import { get, put, del, post } from "../../network/Config/apiService";

// Get All Admin Users
const GetAllAdminUsers = async () => {
  try {
    const res = await get("/admin/admin-users");

    const usersData = res.data.data;

    return {
      result: "success",
      message: "Admin Users data fetched Successfully",
      data: usersData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not fetch Admin Users data",
      data: [],
    };
  }
};

// Get Admin User Details by UID
const GetAdminUserDetails = async (uid) => {
  try {
    const res = await get(`/admin/user/${uid}`);

    const userData = res.data.data;

    return {
      result: "success",
      message: "User data fetched Successfully",
      userData: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not fetch user data",
      userData: null,
    };
  }
};

const UpdateAdminUser = async (uid, data) => {
  try {
    await put(`/admin/user/${uid}`, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not update user profile",
      data: null,
    };
  }
};

const DeleteAdminUser = async (uid) => {
  try {
    await del(`/admin/user/${uid}`);

    return {
      result: "success",
      message: "User deleted successfully",
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Could not delete user",
    };
  }
};

const AddAdminUser = async (data) => {
  try {
    await post("/admin/admin-user/add", {
      email: data.email,
      fullName: data.name,
      password: data.password,
    });

    return {
      result: "success",
      message: "User added successfully",
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || "Could not add user",
    };
  }
};

export { GetAllAdminUsers, GetAdminUserDetails, UpdateAdminUser, DeleteAdminUser, AddAdminUser };
