import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  Row,
  RSelect,
} from "../../../components/Component";
import { GetAllSubscriptions } from "../../../network/apis/subscriptions";
import { toast } from "react-toastify";
import SubscriptionCard from "./SubscriptionCard";

const AllSubscriptions = () => {
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [selectedFilterStatus, setSelectedFilterStatus] = useState("");

  const sortingFunc = (params) => {
    setSortState(params);
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = transactionData.filter((item) => {
        return (
          item?.subscriptionId?.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item?.userName?.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item?.planData?.title?.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item?.tradingPlatformId?.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...transactionData]);
    }
  }, [onSearchText, transactionData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [fetching, setFetching] = useState(false);

  const fetchSubscriptions = async () => {
    try {
      setFetching(true);
      const subscriptionsRes = await GetAllSubscriptions();

      if (subscriptionsRes.result === "success") {
        setTransactionData(subscriptionsRes.subscriptions);
        setData(subscriptionsRes.subscriptions);
      } else {
        toast.error(subscriptionsRes.message);
        setTransactionData([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "An error occurred");
      setTransactionData([]);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newData = transactionData;

    // Filter
    if (selectedFilterStatus) {
      newData = newData.filter((item) => item?.status?.toLowerCase() === selectedFilterStatus?.toLowerCase());
    }

    // Sorting
    if (sort) {
      if (sort === "asc") {
        newData = newData.sort((a, b) => a.date.localeCompare(b.date));
      } else if (sort === "dsc") {
        newData = newData.sort((a, b) => b.date.localeCompare(a.date));
      }
    }

    // Pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = newData.slice(indexOfFirstItem, indexOfLastItem);
    setData(currentItems);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, itemPerPage, sort, transactionData, selectedFilterStatus]);

  return (
    <React.Fragment>
      <Head title="Payment History"></Head>
      {fetching ? (
        <Content>
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        </Content>
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>All Subscriptions</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {transactionData.length} subscriptions.</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">All Subscriptions</h5>
                    </div>
                    <div className="card-tools me-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                            <Icon name="search"></Icon>
                          </Button>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                              <Icon name="filter-alt"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end className="filter-wg dropdown-menu-lg" style={{ overflow: "visible" }}>
                              <div className="dropdown-head">
                                <span className="sub-title dropdown-title">Filter Users</span>
                              </div>
                              <div className="dropdown-body dropdown-body-rg">
                                <Row className="gx-6 gy-3">
                                  <Col size="12">
                                    <div className="form-group">
                                      <label className="overline-title overline-title-alt">Status</label>
                                      <RSelect
                                        options={[
                                          {
                                            label: "Active",
                                            value: "active",
                                          },
                                          {
                                            label: "Expired",
                                            value: "expired",
                                          },
                                          {
                                            label: "Cancelled",
                                            value: "cancelled",
                                          },
                                          {
                                            label: "Deleted",
                                            value: "deleted",
                                          },
                                        ]}
                                        placeholder="Any Status"
                                        onChange={(e) => {
                                          setSelectedFilterStatus(e.value);
                                        }}
                                        value={{
                                          label: selectedFilterStatus ?? "Any Status",
                                          value: selectedFilterStatus ?? "",
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="dropdown-foot between">
                                <a
                                  href="#reset"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSelectedFilterStatus("");
                                  }}
                                  className="clickable"
                                >
                                  Reset Filter
                                </a>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="setting"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-check">
                                <li>
                                  <span>Show</span>
                                </li>

                                <li className={itemPerPage === 10 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(10);
                                    }}
                                  >
                                    10
                                  </DropdownItem>
                                </li>
                                <li className={itemPerPage === 15 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(15);
                                    }}
                                  >
                                    15
                                  </DropdownItem>
                                </li>
                                <li className={itemPerPage === 30 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(30);
                                    }}
                                  >
                                    30
                                  </DropdownItem>
                                </li>
                              </ul>
                              <ul className="link-check">
                                <li>
                                  <span>Order</span>
                                </li>
                                <li className={sort === "dsc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("dsc");
                                      sortingFunc("dsc");
                                    }}
                                  >
                                    DESC
                                  </DropdownItem>
                                </li>
                                <li className={sort === "asc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("asc");
                                      sortingFunc("asc");
                                    }}
                                  >
                                    ASC
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="form-control border-transparent form-focus-none"
                          placeholder="Search by ID, User, Plan"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  {data.length > 0
                    ? data.map((item) => {
                        return <SubscriptionCard item={item} refreshUI={fetchSubscriptions} />;
                      })
                    : null}
                </div>
                <div className="card-inner">
                  {transactionData.length > 0 ? (
                    <PaginationComponent
                      noDown
                      itemPerPage={itemPerPage}
                      totalItems={transactionData.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default AllSubscriptions;
