import { get, put, del } from "../../network/Config/apiService";

const GetAllTransactions = async () => {
  const res = await get("/admin/transactions");

  const transactions = res.data.transactions;

  return {
    result: "success",
    message: "Transactions fetched successfully",
    transactions: transactions,
  };
};

const GetTransactionsOfUser = async (userId) => {
  if (!userId) {
    throw new Error("User ID is required");
  }

  const res = await get(`/admin/transactions/${userId}`);

  const transactions = res.data.transactions;

  return {
    result: "success",
    message: "Transactions fetched successfully",
    transactions: transactions,
  };
};

const DeleteTransaction = async (transactionId) => {
  if (!transactionId) {
    throw new Error("Transaction ID is required");
  }

  await del(`/admin/transaction/delete/${transactionId}`);

  return {
    result: "success",
    message: "Transaction deleted successfully",
  };
};

const UpdateTransactionStatus = async (transactionId, status) => {
  if (!transactionId) {
    throw new Error("Transaction ID is required");
  }

  if (!status) {
    throw new Error("Status is required");
  }

  await put(`/admin/transaction/update-status/${transactionId}`, { status });

  return {
    result: "success",
    message: "Transaction status updated successfully",
  };
};

export { GetAllTransactions, GetTransactionsOfUser, DeleteTransaction, UpdateTransactionStatus };
