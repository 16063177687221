import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout, setUserData } from "./redux/slices/UserSlice";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { GetUpdatedProfile } from "./network/apis/auth";

const App = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { user, token } = useSelector((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  const clearDataForInvalidLogin = () => {
    dispatch(logout());
    setIsLoggedIn(false);
    setFetchingUserData(false);
    setIsEmailVerified(true);
    dispatch(
      setUserData({
        user: null,
      })
    );
    setTimeout(() => {
      dispatch(logout());
      navigator("/");
    }, 5000);
  };

  useEffect(() => {
    if (user && user.emailVerified) {
      setIsEmailVerified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getLoggedInUser = async () => {
    const res = await GetUpdatedProfile();

    if (res.result === "success") {
      const user = res.userData;

      if (
        res?.userData?.role === "admin" &&
        res?.userData?.isAdmin &&
        res?.userData?.status?.toLowerCase() === "active"
      ) {
        setFetchingUserData(false);
        setIsLoggedIn(true);
        setIsEmailVerified(user.emailVerified);
        dispatch(
          setUserData({
            user: res.userData,
          })
        );
      } else if (
        res?.userData?.role === "admin" &&
        res?.userData?.isAdmin &&
        res?.userData?.status?.toLowerCase() !== "active"
      ) {
        if (res?.userData?.status?.toLowerCase() === "pending") {
          toast.warn("Your account is pending for approval. Please wait for admin approval.");
        } else if (res?.userData?.status?.toLowerCase() === "suspend") {
          toast.warn("Your account has been suspended. Please contact admin for more information.");
        }
        clearDataForInvalidLogin();
      } else {
        clearDataForInvalidLogin();
        toast.error("You are not authorized to access admin panel.");
      }
    } else {
      clearDataForInvalidLogin();
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (token) {
      getLoggedInUser();
    } else {
      clearDataForInvalidLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <ThemeProvider>
      {fetchingUserData ? (
        <>
          <section className="d-flex justify-content-center align-items-center vh-100">
            <Spinner size="lg" color="primary" />
          </section>
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <Router authenticated={isLoggedIn} emailVerified={isEmailVerified} />
          ) : (
            <Router authenticated={false} emailVerified={false} />
          )}
        </>
      )}
    </ThemeProvider>
  );
};
export default App;
