import React from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import AuthFooter from "./AuthFooter";

function VerifyEmail() {
  const verifingEmail = false;
  const emailVerified = false;

  return (
    <>
      <Head title="Success" />
      <Block className="nk-block-middle nk-auth-body h-100vh">
        <div className="brand-logo pb-5">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">
              {verifingEmail ? "Verifying Email" : emailVerified ? "Email Verified" : "Email Verification Failed"}
            </BlockTitle>
            {verifingEmail ? (
              <section className="d-flex justify-content-start align-items-start my-5">
                <Spinner size="lg" color="primary" />
              </section>
            ) : (
              <>
                {emailVerified ? (
                  <BlockDes className="text-success">
                    <p>
                      Your email has been verified successfully. You can now login to your account using your email and
                      password.
                    </p>
                    <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                      <Button color="primary" size="lg">
                        Back to Login
                      </Button>
                    </Link>
                  </BlockDes>
                ) : (
                  <BlockDes className="text-danger">
                    <p>Verification failed. Please try again later.</p>
                    <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                      <Button color="primary" size="lg">
                        Resend Verification Email
                      </Button>
                    </Link>
                  </BlockDes>
                )}
              </>
            )}
          </BlockContent>
        </BlockHead>
      </Block>
      <AuthFooter />
    </>
  );
}

export default VerifyEmail;
