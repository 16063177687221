import axios from "axios";
import { setupInterceptors } from "./interceptors";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Setup interceptors
setupInterceptors(client);

const get = async (url, params = {}, config = {}) => {
  return client.get(url, { params, ...config }).then((response) => response);
};

const post = async (url, data = {}, config = {}) => {
  return client.post(url, data, config).then((response) => response);
};

const put = async (url, data = {}, config = {}) => {
  return client.put(url, data, config).then((response) => response);
};

const del = async (url, config = {}) => {
  return client.delete(url, config).then((response) => response);
};

export { get, post, put, del, client };
