const menu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/",
  },
  {
    icon: "users",
    text: "User Manage",
    link: "/users",
  },
  {
    icon: "report-profit",
    text: "All Subscriptions",
    link: "/subscriptions",
  },
  {
    icon: "tranx",
    text: "All Payments",
    link: "/history-payment",
  },
  {
    icon: "view-col",
    text: "Pricing Tables",
    link: "/pricing-table",
  },
  {
    icon: "lock-alt",
    text: "Admin Users",
    link: "/admin-users",
  },
];
export default menu;
