import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Card, Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/Utils";
import { Button, Icon } from "../../../components/Component";
import { DeleteSubscription } from "../../../network/apis/subscriptions";
import EditSubscriptionModel from "./EditSubscriptionModel";

function SubscriptionCard({ item, refreshUI }) {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const getSubscriptionEndData = (joinDate, planDuration = 1) => {
    const date = new Date(joinDate);
    const nextBillingDate = new Date(date.setMonth(date.getMonth() + planDuration));

    return nextBillingDate.toDateString();
  };

  const getTotalPaidAmount = () => {
    const currency = item?.planData?.currency;
    let totalAmount = 0;

    if (currency === "IRR") {
      const totalAmountWithoutBot = Number(item?.planData?.withoutBotTomansPrice) * item?.planData?.duration;
      const totalAmountWithBot = Number(item?.planData?.withBotTomansPrice) * item?.planData?.duration;

      totalAmount = item?.planData?.isBotIncluded ? totalAmountWithBot : totalAmountWithoutBot;
    } else {
      const totalAmountWithoutBot = Number(item?.planData?.withoutBotUsdPrice) * item?.planData?.duration;
      const totalAmountWithBot = Number(item?.planData?.withBotUsdPrice) * item?.planData?.duration;

      totalAmount = item?.planData?.isBotIncluded ? totalAmountWithBot : totalAmountWithoutBot;
    }

    const formattedAmount = `${currency} ${totalAmount}`;

    return formattedAmount;
  };

  const deleteSubscription = async (subscriptionId) => {
    try {
      setIsDeleting(true);
      const res = await DeleteSubscription(subscriptionId);

      if (res.result === "success") {
        toast.success(res.message);
        refreshUI();
        setShowDeleteModal(false);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? "An error occurred");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Card
        className="card sp-plan mb-3"
        style={{
          borderBottom: "2px solid #dbdfea",
          borderTop: "2px solid #dbdfea",
        }}
      >
        <Row className="no-gutters">
          <Col md="8">
            <div className="sp-plan-info card-inner">
              <Row className="gx-0 gy-3">
                <Col sm="12">
                  <div className="sp-plan-name">
                    <h6 className="title text-primary">
                      {item?.planData?.title}{" "}
                      <Badge
                        color={
                          item?.status?.toLowerCase() === "active"
                            ? "success"
                            : item?.status?.toLowerCase() === "expired"
                            ? "warning"
                            : "danger"
                        }
                        className="rounded-pill"
                        style={{
                          textTransform: "capitalize",
                          marginTop: "-4px",
                        }}
                      >
                        {item?.status ?? "Inactive"}
                      </Badge>
                    </h6>
                    <p
                      style={{
                        marginBottom: "2px",
                        fontWeight: "500",
                      }}
                    >
                      Subscription ID: <span className="text-base">{item?.subscriptionId}</span>
                    </p>
                    <p
                      style={{
                        marginBottom: "2px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/user/${item?.userId}`);
                      }}
                    >
                      User Name: <span className="text-base ">{item?.userName}</span>
                    </p>
                    <p
                      style={{
                        marginBottom: "2px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/user/${item?.userId}`);
                      }}
                    >
                      User Email: <span className="text-base">{item?.email}</span>
                    </p>
                    <p
                      style={{
                        marginBottom: "2px",
                        fontWeight: "500",
                      }}
                    >
                      TradingView ID: <span className="text-base">{item?.tradingPlatformId ?? "N/A"}</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sp-plan-desc card-inner">
              <Row className="gx-1 gy-1 mt-4">
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Started On</span> {formatDate(item?.date, "DD MMM YYYY")}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Expires On</span>{" "}
                    {getSubscriptionEndData(item?.date, item?.planData?.duration)}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Price</span>
                    {getTotalPaidAmount()}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Duration </span>
                    {item?.planData?.duration} {item?.planData?.duration > 1 ? "Months" : "Month"}
                  </p>
                </Col>
                {item?.planData?.isBotOptionAvailable && (
                  <Col col="6" lg="3">
                    <p>
                      <span className="text-soft">Strategy Bot Access</span>{" "}
                      {item?.planData?.isBotIncluded ? "Yes" : "No"}
                    </p>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col md="4">
            <div className="sp-plan-action card-inner">
              <button
                className="btn btn-primary mt-1"
                onClick={() => {
                  setShowUpdateModal({ editSubscription: true });
                }}
              >
                Update Subscription
              </button>
              <button
                className="btn btn-link text-danger mt-1"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              >
                Delete Subscription
              </button>
            </div>
          </Col>
        </Row>
      </Card>

      {/* Delete Modal */}
      <Modal
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(false)}
        className="modal-dialog-centered"
        size="md"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setShowDeleteModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="nk-modal-head">
            <h4 className="nk-modal-title title">
              Are you sure you want to delete this subscription{" "}
              <span className="text-primary">{item?.subscriptionId}</span>?
            </h4>
          </div>

          <div className="nk-modal-head mt-4">
            <Button
              className="btn btn-primary"
              disabled={isDeleting}
              onClick={() => {
                deleteSubscription(item?.subscriptionId);
              }}
            >
              {isDeleting ? <Spinner size="sm" color="light" /> : "Delete Transaction"}
            </Button>

            <Button
              onClick={() => setShowDeleteModal(false)}
              className="btn btn-outline-secondary mx-2"
              disabled={isDeleting}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Update Modal */}
      <EditSubscriptionModel
        modal={showUpdateModal}
        setModal={setShowUpdateModal}
        refetchData={() => refreshUI()}
        data={item}
      />
    </>
  );
}

export default SubscriptionCard;
