import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { Icon, Col, Button, RSelect, Row } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { SendPasswordResetEmail } from "../../../network/apis/profile";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { iconsType } from "../../app/file-manager/components/Icons";
import { bytesToMegaBytes } from "../../../utils/Utils";
import { UpdateUserProfileImage } from "../../../network/apis/users";

const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "France", label: "France" },
  { value: "England", label: "England" },
];

const EditModal = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  updating = false,
  userUid = "",
  refreshData = () => {},
}) => {
  useEffect(() => {
    reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  const {
    reset,
    register,
    formState: { errors },
  } = useForm();
  const [sendingResetPassword, setSendingResetPassword] = useState(false);
  const [modalTab, setModalTab] = useState("1");

  const [profileImage, setProfileImage] = useState(null);
  const [isUpdatingProfileImage, setIsUpdatingProfileImage] = useState(false);

  const resetPassword = async (email) => {
    try {
      setSendingResetPassword(true);
      // Call the ResetPassword function from the auth.js file in the firebase/functions folder
      const res = await SendPasswordResetEmail(email);

      setSendingResetPassword(false);
      if (res.result === "error") {
        toast.error(res.message);
      } else {
        toast.success("Reset link sent to email address");
      }
    } catch (error) {
      setSendingResetPassword(false);
      toast.error(error?.message ?? "Cannot send reset link");
    }
  };

  const updateUserProfileImage = async () => {
    if (!profileImage) {
      toast.error("Please select a file to upload");
    } else {
      try {
        setIsUpdatingProfileImage(true);
        const res = await UpdateUserProfileImage(userUid, profileImage);

        if (res.result === "success") {
          toast.success("Profile image updated successfully");
          refreshData();
          closeModal();
        } else {
          toast.error(res?.message ?? "Error updating profile image");
        }

        setIsUpdatingProfileImage(false);
      } catch (error) {
        setIsUpdatingProfileImage(false);
        toast.error(error?.message ?? "Error updating profile image");
      }
    }
  };

  const handleDropChange = (acceptedFiles) => {
    // Check if file is not empty
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setProfileImage(file);
    } else {
      setFormData({ ...formData, photoURL: "" });
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Update User</h5>

          {/* Tabs */}
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                Personal
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#address"
              >
                Address
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "3" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("3");
                }}
                href="#address"
              >
                Profile Image
              </a>
            </li>
          </ul>

          <div className="mt-4">
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                <Row className="gy-4 mt-1">
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Full Name</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("name", { required: "This field is required" })}
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        placeholder="Enter name"
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="display-name">
                        Display Name
                      </label>
                      <input
                        type="text"
                        id="display-name"
                        className="form-control"
                        name="displayName"
                        onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
                        defaultValue={formData.displayName}
                        placeholder="Enter display name"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        placeholder="Enter email"
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        {...register("phone", {})}
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">TradingView ID</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("tradingPlatformId", {})}
                        value={formData.tradingPlatformId}
                        onChange={(e) => setFormData({ ...formData, tradingPlatformId: e.target.value })}
                      />
                      {errors.tradingPlatformId && <span className="invalid">{errors.tradingPlatformId.message}</span>}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          value={{
                            value: formData.status,
                            label: formData.status,
                          }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="md"
                          disabled={updating || sendingResetPassword}
                          onClick={(ev) => {
                            ev.preventDefault();
                            onSubmit(formData);
                          }}
                        >
                          {updating ? (
                            <span>
                              <Spinner size="sm" color="light" />
                            </span>
                          ) : (
                            "Update User"
                          )}
                        </Button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={(ev) => {
                            ev.preventDefault();
                            closeModal();
                          }}
                          disabled={updating || sendingResetPassword}
                          className="link link-light"
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </Col>
                  <Col size="12">
                    <Button
                      outline={true}
                      color="primary"
                      size="md"
                      type="button"
                      disabled={updating}
                      onClick={() => resetPassword(formData.email)}
                    >
                      {sendingResetPassword ? (
                        <span>
                          <Spinner size="sm" color="light" />
                        </span>
                      ) : (
                        "Send Reset Password Email"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>

              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="address">
                <Row className="gy-4 mt-1">
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l1">
                        Address Line 1
                      </label>
                      <input
                        type="text"
                        id="address-l1"
                        name="address"
                        onChange={(e) => {
                          setFormData({ ...formData, address: e.target.value });
                        }}
                        defaultValue={formData.address}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l2">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        id="address-l2"
                        name="address2"
                        onChange={(e) => {
                          setFormData({ ...formData, address2: e.target.value });
                        }}
                        defaultValue={formData.address2}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-st">
                        State
                      </label>
                      <input
                        type="text"
                        id="address-st"
                        name="state"
                        onChange={(e) => {
                          setFormData({ ...formData, state: e.target.value });
                        }}
                        defaultValue={formData.state}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-county">
                        Country
                      </label>
                      <RSelect
                        options={countryOptions}
                        placeholder="Select a country"
                        defaultValue={[
                          {
                            value: formData.country,
                            label: formData.country,
                          },
                        ]}
                        onChange={(e) => setFormData({ ...formData, country: e.value })}
                      />
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onSubmit(formData);
                          }}
                          disabled={updating}
                        >
                          {updating ? <Spinner size="sm" color="light" /> : "Update Address"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            closeModal();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "3" ? "active" : ""}`} id="profile-image">
                <Row className="gy-4 mt-1">
                  <div className="form-group">
                    <div className="nk-upload-form w-full">
                      <h5 className="title mb-3">Upload Profile Image</h5>
                      <Dropzone
                        onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
                        accept={"image/*"}
                        maxSize={1048576}
                        maxFiles={1}
                        onDropRejected={(fileRejections) => {
                          if (fileRejections.length > 0) {
                            // toast.error("File size is too large. Max file size is 1MB");
                            toast.error(
                              fileRejections[0]?.errors[0]?.message ?? "File size is too large. Max file size is 1MB"
                            );
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">
                                  <span>Drag and drop</span> file here or <span>browse</span>{" "}
                                  <span className="text-soft"> (Max 1MB)</span>
                                </span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      {profileImage && (
                        <div className="nk-upload-item">
                          <div className="nk-upload-icon">
                            {iconsType[profileImage.type] ? iconsType[profileImage.type] : iconsType["others"]}
                          </div>
                          <div className="nk-upload-info">
                            <div className="nk-upload-title">
                              <span className="title">{profileImage.name}</span>
                            </div>
                            <div className="nk-upload-size">{bytesToMegaBytes(profileImage.size)} MB</div>
                          </div>
                          <div className="nk-upload-action">
                            <a
                              href="#delete"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setProfileImage(null);
                              }}
                              className="btn btn-icon btn-trigger"
                            >
                              <Icon name="trash"></Icon>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            updateUserProfileImage();
                          }}
                          disabled={isUpdatingProfileImage || updating}
                        >
                          {isUpdatingProfileImage ? <Spinner size="sm" color="light" /> : "Update Profile Image"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            closeModal();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default EditModal;
