import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody } from "reactstrap";
import { Button, Col, Icon } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UpdatePricePlan } from "../../../network/apis/pricePlan";

function EditPricingPlanModel({ modal, setModal, refetchData, selectedPlan }) {
  const {
    reset,
    register,
    formState: { errors },
  } = useForm();

  const [addingPricePlan, setAddingPricePlan] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    isPopular: false,
    isForFarsiUsers: false,
    isBotOptionAvailable: true,
    withoutBotUsdPrice: 0,
    withoutBotTomansPrice: 0,
    withBotUsdPrice: 0,
    withBotTomansPrice: 0,
    fetures: [],
    extraFeatures: [],
  });

  const onFormReset = () => {
    setModal({ add: false });
  };

  const [featureList, setFeatureList] = useState([""]);
  const [extraFeatureList, setExtraFeatureList] = useState([""]);

  const editPricePlan = async () => {
    if (
      formData?.title?.trim() !== "" &&
      formData?.description?.trim() !== "" &&
      formData?.withBotUsdPrice > 0 &&
      formData?.withBotTomansPrice > 0 &&
      featureList.length > 0 &&
      featureList?.every((feature) => feature.trim() !== "") &&
      // Check if isBotOptionAvailable is true then check for extra features & prices
      (formData?.isBotOptionAvailable ? extraFeatureList.length > 0 : true) &&
      (formData?.isBotOptionAvailable ? extraFeatureList?.every((feature) => feature.trim() !== "") : true) &&
      (formData?.isBotOptionAvailable ? formData?.withoutBotUsdPrice > 0 : true) &&
      (formData?.isBotOptionAvailable ? formData?.withoutBotTomansPrice > 0 : true)
    ) {
      try {
        setAddingPricePlan(true);
        const features = featureList.filter((feature) => feature.trim() !== "");
        const extraFeatures = extraFeatureList.filter((feature) => feature.trim() !== "");

        const pricePlan = {
          id: selectedPlan.id,
          title: formData.title,
          description: formData.description,
          features: features,
          isPopular: formData.isPopular ? true : false,
          isForFarsiUsers: formData.isForFarsiUsers ? true : false,
          isBotOptionAvailable: formData.isBotOptionAvailable ? true : false,
          withoutBotUsdPrice: formData.withoutBotUsdPrice,
          withoutBotTomansPrice: formData.withoutBotTomansPrice,
          withBotUsdPrice: formData.withBotUsdPrice ?? formData.withoutBotUsdPrice,
          withBotTomansPrice: formData.withBotTomansPrice ?? formData.withoutBotTomansPrice,
          extraFeatures: extraFeatures ?? [],
        };

        const res = await UpdatePricePlan(pricePlan);

        if (res.status === "success") {
          toast.success(res.message);
          refetchData();
          setModal({ edit: false });
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setAddingPricePlan(false);
      }
    } else {
      toast.error("Please fill all the fields");
      setAddingPricePlan(false);
      return;
    }
  };

  useEffect(() => {
    reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (selectedPlan) {
      setFormData({
        title: selectedPlan.title || "",
        description: selectedPlan.description || "",
        isPopular: selectedPlan.isPopular || false,
        isForFarsiUsers: selectedPlan.isForFarsiUsers || false,
        extraFeatures: selectedPlan.extraFeatures || [],
        fetures: selectedPlan.features || [],
        isBotOptionAvailable: selectedPlan.isBotOptionAvailable || false,
        withBotTomansPrice: selectedPlan.withBotTomansPrice || 0,
        withBotUsdPrice: selectedPlan.withBotUsdPrice || 0,
        withoutBotTomansPrice: selectedPlan.withoutBotTomansPrice || 0,
        withoutBotUsdPrice: selectedPlan.withoutBotUsdPrice || 0,
      });

      setFeatureList(selectedPlan.features || [""]);
      setExtraFeatureList(selectedPlan.extraFeatures || [""]);
    }
  }, [selectedPlan]);

  return (
    <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            onFormReset();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <div className="p-2">
          <h4 className="title">
            Update Pricing Plan
            <span className="fs-6 fw-normal text-muted d-block mt-1">Update pricing plan details below</span>
          </h4>
          <div className="mt-4">
            <Form
              className="row gy-4 mt-4"
              onSubmit={(e) => {
                e.preventDefault();
                editPricePlan();
              }}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("title", { required: "This field is required" })}
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    placeholder="Enter title"
                  />
                  {errors.title && <span className="invalid">{errors.title.message}</span>}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("description", { required: "This field is required" })}
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    placeholder="Enter description"
                  />
                  {errors.description && <span className="invalid">{errors.description.message}</span>}
                </div>
              </Col>

              <div className="col-md-12">
                <h5 className="title">Pricing Without Bot</h5>
              </div>

              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Without Bot Price in USD</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("withoutBotUsdPrice", { required: "This field is required" })}
                    value={formData.withoutBotUsdPrice}
                    onChange={(e) => setFormData({ ...formData, withoutBotUsdPrice: e.target.value })}
                    placeholder="Enter without bot price in USD"
                  />
                  {errors.withoutBotUsdPrice && <span className="invalid">{errors.withoutBotUsdPrice.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Without Bot Price in Tomans (IRR)</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("withoutBotTomansPrice", { required: "This field is required" })}
                    value={formData.withoutBotTomansPrice}
                    onChange={(e) => setFormData({ ...formData, withoutBotTomansPrice: e.target.value })}
                    placeholder="Enter without bot price in Tomans (IRR)"
                  />
                  {errors.withoutBotTomansPrice && (
                    <span className="invalid">{errors.withoutBotTomansPrice.message}</span>
                  )}
                </div>
              </Col>

              <Col md="12">
                <div className="form-group flex align-items-center gap-1">
                  <input
                    type="checkbox"
                    {...register("isBotOptionAvailable")}
                    name="isBotOptionAvailable"
                    id="isBotOptionAvailable"
                    value={formData.isBotOptionAvailable}
                    onChange={(e) => setFormData({ ...formData, isBotOptionAvailable: e.target.checked })}
                  />
                  <label className="form-label mx-1" htmlFor="isBotOptionAvailable">
                    Is Bot Option Available
                  </label>
                </div>
              </Col>

              {formData.isBotOptionAvailable && (
                <>
                  <div className="col-md-12">
                    <h5 className="title mt-3">Pricing With Bot</h5>
                  </div>

                  <Col md="6" className="mt-2">
                    <div className="form-group">
                      <label className="form-label">With Bot Price in USD</label>
                      <input
                        className="form-control"
                        type="number"
                        {...register("withBotUsdPrice", { required: "This field is required" })}
                        value={formData.withBotUsdPrice}
                        onChange={(e) => setFormData({ ...formData, withBotUsdPrice: e.target.value })}
                        placeholder="Enter with bot price in USD"
                      />
                      {errors.withBotUsdPrice && <span className="invalid">{errors.withBotUsdPrice.message}</span>}
                    </div>
                  </Col>

                  <Col md="6" className="mt-2">
                    <div className="form-group">
                      <label className="form-label">With Bot Price in Tomans (IRR)</label>
                      <input
                        className="form-control"
                        type="number"
                        {...register("withBotTomansPrice", { required: "This field is required" })}
                        value={formData.withBotTomansPrice}
                        onChange={(e) => setFormData({ ...formData, withBotTomansPrice: e.target.value })}
                        placeholder="Enter with bot price in Tomans (IRR)"
                      />
                      {errors.withBotTomansPrice && (
                        <span className="invalid">{errors.withBotTomansPrice.message}</span>
                      )}
                    </div>
                  </Col>
                </>
              )}

              <Col md="12">
                <div className="form-group flex align-items-center gap-1">
                  <input
                    type="checkbox"
                    {...register("isPopular")}
                    name="isPopularCheck"
                    id="isPopularCheck"
                    value={formData.isPopular}
                    onChange={(e) => setFormData({ ...formData, isPopular: e.target.checked })}
                  />
                  <label className="form-label mx-1" htmlFor="isPopularCheck">
                    Show Popular Badge
                  </label>
                </div>
              </Col>

              <Col md="12">
                <div className="form-group flex align-items-center gap-1">
                  <input
                    type="checkbox"
                    {...register("isForFarsiUsers")}
                    name="isForFarsiUsersCheck"
                    id="isForFarsiUsersCheck"
                    value={formData.isForFarsiUsers}
                    onChange={(e) => setFormData({ ...formData, isForFarsiUsers: e.target.checked })}
                  />
                  <label className="form-label mx-1" htmlFor="isForFarsiUsersCheck">
                    Is for Farsi Users
                  </label>
                </div>
              </Col>

              <Col md="12">
                <label className="form-label">Features</label>
                <div
                  className="flex flex-column"
                  style={{
                    height: "fit-content",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {featureList.map((feature, index) => {
                    return (
                      <div key={index} className="flex align-items-center position-relative mb-1">
                        <input
                          type="text"
                          className="form-control"
                          value={feature}
                          onChange={(e) => {
                            featureList[index] = e.target.value;
                            setFeatureList([...featureList]);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              setFeatureList([...featureList, ""]);
                            }
                          }}
                          placeholder="Enter feature"
                        />
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-icon position-absolute end-0 top-0"
                            onClick={() => {
                              featureList.splice(index, 1);
                              setFeatureList([...featureList]);
                            }}
                          >
                            <Icon name="trash-fill"></Icon>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  className="btn btn-md w-100 justify-content-center"
                  onClick={() => {
                    setFeatureList([...featureList, ""]);
                  }}
                >
                  <Icon name="plus-round" className="" />{" "}
                  <span
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginLeft: "5px",
                      marginBottom: "-1px",
                    }}
                  >
                    Add New Feature
                  </span>
                </button>
              </Col>

              {formData.isBotOptionAvailable && (
                <Col md="12">
                  <label className="form-label">Extra Features With Bot</label>
                  <div
                    className="flex flex-column"
                    style={{
                      height: "fit-content",
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {extraFeatureList.map((feature, index) => {
                      return (
                        <div key={index} className="flex align-items-center position-relative mb-1">
                          <input
                            type="text"
                            className="form-control"
                            value={feature}
                            onChange={(e) => {
                              extraFeatureList[index] = e.target.value;
                              setExtraFeatureList([...extraFeatureList]);
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                setExtraFeatureList([...extraFeatureList, ""]);
                              }
                            }}
                            placeholder="Enter feature"
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-icon position-absolute end-0 top-0"
                              onClick={() => {
                                extraFeatureList.splice(index, 1);
                                setExtraFeatureList([...extraFeatureList]);
                              }}
                            >
                              <Icon name="trash-fill"></Icon>
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <button
                    type="button"
                    className="btn btn-md w-100 justify-content-center"
                    onClick={() => {
                      setExtraFeatureList([...extraFeatureList, ""]);
                    }}
                  >
                    <Icon name="plus-round" className="" />{" "}
                    <span
                      style={{
                        margin: "0px",
                        padding: "0px",
                        marginLeft: "5px",
                        marginBottom: "-1px",
                      }}
                    >
                      Add New Feature
                    </span>
                  </button>
                </Col>
              )}

              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={false}>
                      {addingPricePlan ? "Updating..." : "Update"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onFormReset();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default EditPricingPlanModel;
