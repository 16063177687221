import { get, post } from "../../network/Config/apiService";

const RegisterWithEmailAndPassword = async (fullName, email, password) => {
  try {
    // Check if email is already in use
    const res = await post("/auth/register", {
      email: email,
      password: password,
      fullName: fullName,
    });

    return {
      result: "success",
      message: "User registered successfully",
      data: res.data.data,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message ?? error.message ?? "Cannot register with credentials",
      data: null,
    };
  }
};

const LoginWithEmailAndPassword = async (email, password) => {
  try {
    const res = await post("/auth/login", {
      email: email,
      password: password,
    });

    return {
      result: "success",
      message: "User logged in successfully",
      data: res.data.data,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message ?? error.message ?? "Cannot login with credentials",
      data: null,
    };
  }
};

const GetUpdatedProfile = async () => {
  try {
    const res = await get("/user/profile");

    return {
      result: "success",
      message: "User logged in successfully",
      userData: res.data.data,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message ?? error.message ?? "Cannot login with credentials",
      userData: null,
    };
  }
};

const ResetPassword = async (email) => {
  try {
    await post("/auth/send-reset-password-link", {
      email: email,
    });
    return {
      result: "success",
      message: "Reset link sent to your email address",
      data: null,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message ?? error.message ?? "Cannot send reset link",
      data: null,
    };
  }
};

export { RegisterWithEmailAndPassword, LoginWithEmailAndPassword, GetUpdatedProfile, ResetPassword };
