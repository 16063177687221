import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { post } from "../../../network/Config/apiService";

const AddModal = ({ modal, closeModal, refreshData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    tradingPlatformId: "",
  });

  useEffect(() => {
    reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [addingUser, setAddingUser] = useState(false);

  const onSubmit = async () => {
    if (formData.name === "" || formData.email === "" || formData.phone === "" || formData.password === "") {
      toast.error("All fields are required");
      return;
    }

    setAddingUser(true);

    post(`/auth/register`, {
      fullName: formData.name,
      email: formData.email,
      password: formData.password,
    })
      .then((res) => {
        setAddingUser(false);
        closeModal();
        toast.success("User added successfully");
        if (refreshData && typeof refreshData === "function") {
          refreshData();
        }
      })
      .catch((err) => {
        setAddingUser(false);
        toast.error(err?.response?.data?.message || "Error adding user");
      });
  };

  // add-user
  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add User</h5>
          <div className="mt-4">
            <Form className="row gy-4" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name", { required: "This field is required" })}
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Enter name"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Email </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    placeholder="Enter email"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Phone</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("phone", { required: "This field is required" })}
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  />

                  {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">TradingView ID</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("tradingPlatformId", { required: "This field is required" })}
                    value={formData.tradingPlatformId}
                    onChange={(e) => setFormData({ ...formData, tradingPlatformId: e.target.value })}
                  />

                  {errors.tradingPlatformId && <span className="invalid">{errors.tradingPlatformId.message}</span>}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Password</label>

                  <div className="d-flex align-items-center position-relative">
                    <input
                      className="form-control"
                      type={showPassword ? "text" : "password"}
                      {...register("password", { required: "This field is required" })}
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                    <button
                      type="button"
                      className="btn position-absolute end-0 "
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon name={showPassword ? "eye-off" : "eye"} />
                    </button>
                  </div>

                  {errors.password && <span className="invalid">{errors.password.message}</span>}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={addingUser}>
                      {addingUser ? "Adding user..." : "Add User"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AddModal;
